import React, { Component, useEffect } from 'react';
import { NavMenu } from './Nav/NavMenu';
import { Card, Text, Group, Image, Container, Grid, Space, Title } from "@mantine/core";
import { useMedia } from '../MediaProvider';
import UserProfileCard from './UserProfileCard';
import { usePageTitle } from '../PageTitleContext';

const LayoutContent = ({ children }) => {
  const { isMobile } = useMedia();
  const pageTitle = usePageTitle();

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);
  return (
    <body style={{
      backgroundImage: `url(bg.png)`,
      minHeight: '100vh',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundAttachment: "fixed"
    }}>
      {isMobile ? (
        <Container size="xl" >
          <Space h="md"/>
          <Group justify="space-between" mt="xl" mb="xl">
            <Title order={2} style={{ fontFamily: 'Poppins, serif' }} >&lt;V.Developer/&gt;</Title>
            <NavMenu />
          </Group>
          <Grid mt="xl" mb="xl" justify="center">
            <Grid.Col span={11} >
              <UserProfileCard />
            </Grid.Col>
            <Grid.Col span={12}>
              <Card shadow="sm" padding="lg" radius="xl" withBorder>
                {children}
              </Card>
            </Grid.Col>
          </Grid>
          <Space h="xl" />
        </Container>
      ) : (
        <Container size="80%" >
          <Space h="xl"/>
          <Group justify="flex-start">
              <Title order={2} style={{ fontFamily: 'Poppins, serif' }} >&lt;V.Developer/&gt;</Title>
          </Group>
          <Grid mt="xl" mb="xl">
            <Grid.Col span="content" >
                <UserProfileCard />
            </Grid.Col>
            <Grid.Col span="auto">
              <Card shadow="sm" padding="sm" radius="xl" withBorder>
                {children}
              </Card>
            </Grid.Col>
            <Grid.Col span="content">
              <Card shadow="sm" padding="lg" radius="lg" withBorder style={{ position: "sticky", top: "20px", zIndex: 1 }}>
                <NavMenu />
              </Card>
            </Grid.Col>
          </Grid>
          <Space h="xl" />
        </Container>
      )}
    </body>
  );
};

// Componente de clase que usa el componente funcional
export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return <LayoutContent>{this.props.children}</LayoutContent>;
  }
}

export default Layout;
