import Portfolio from "./components/Projects/Portfolio";
import AddProject from "./components/Projects/AddProject";
import EditProject from "./components/Projects/EditProject";
import ProjectsList from "./components/Projects/ProjectsList";
import Login from "./components/Account/Login";
import Logout from "./components/Account/Logout";
import PrivateRoute from './PrivateRoute';
import AboutSection from './components/AboutSection';
import ResumePage from './components/ResumePage';
import { IconHome, IconBriefcase, IconLogin, IconLogout, IconDashboard, IconFileInfo } from '@tabler/icons-react';

const AppRoutes = [
  {
    path: '/',
    index: true,
    element: <AboutSection />,
    name: "Inicio",
    leftSection: <IconHome size="1.5rem" stroke={1.5} />
  },
  {
    path: '/curriculum',
    element: <ResumePage />,
    name: "Curriculum",
    leftSection: <IconFileInfo size="1.5rem" stroke={1.5} />
  },
  {
    path: '/portfolio',
    element: <Portfolio />,
    name: "Portafolio",
    leftSection: <IconBriefcase size="1.5rem" stroke={1.5} />
  },
  {
    path: '/login',
    element: <Login />,
    name: "Login",
    leftSection: <IconLogin size="1.5rem" stroke={1.5} />
  },
  {
    path: '/projects',
    element: (
      <PrivateRoute>
        <ProjectsList />
      </PrivateRoute>
    ),
    name: "Proyectos",
    leftSection: <IconDashboard size="1.5rem" stroke={1.5} />
  },
  {
    path: '/add-project',
    element: (
      <PrivateRoute>
        <AddProject />
      </PrivateRoute>
    ),
    name: "A�adir Proyecto"
  },
  {
    path: '/edit-project/:id',
    element: (
      <PrivateRoute>
        <EditProject />
      </PrivateRoute>
    ),
    name: "Editar Proyecto"
  },
  {
    path: '/salir',
    element: (
      <PrivateRoute>
        <Logout />
      </PrivateRoute>
    ),
    name: "Salir",
    leftSection: <IconLogout size="1.5rem" stroke={1.5} />
  }
];

export default AppRoutes;
