import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Card, Text, Loader, Center, Group, Title, Badge, Divider, Space, Container } from "@mantine/core";
import { FaGithub } from 'react-icons/fa';
import CarruselComponent from './CarruselComponent';

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
};


function Portfolio() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get('api/project');
      const sortedProjects = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setProjects(sortedProjects);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  if (loading) {
    return (
      <Center>
        <Loader color="blue" size="xl" type="dots" />
      </Center>
    );
  }

  return (
    <div>
      <div style={{ padding: '40px', paddingBottom: '0px' }}>
        <Title size="h1" style={{ fontFamily: 'Roboto Slab, serif'}}>Portafolio</Title>
       </div>
      {projects.map((project, index) => (
        <Card key={index} shadow="sm" padding="lg" radius="xl" mt="xl" withBorder>
          <Card.Section>
            <CarruselComponent images={project.photos} />
          </Card.Section>
          <Group justify="space-between"  mt="xs">
            <Text size = "xl" fw={500}>
              {project.name}
            </Text>
            <Text size="sm" c="dimmed" fs="italic">
              {formatDate(project.date)}
            </Text>
          </Group>

          <Group justify="space-between" mt="md">
            <Group mb="xs">
              {project.tags.map((tag, index) => (
                <Badge key={index} color="pink">{tag}</Badge>
              ))}
            </Group>
            <Group mb="xs">
              {project.githubLink && (
                <a href={project.githubLink} target="_blank" rel="noopener noreferrer">
                  <FaGithub size={24} />
                </a>
              )}
            </Group>
          </Group>
          <Text size="md" dangerouslySetInnerHTML={{ __html: project.description }} />
          <Text size="xs" c="dimmed">
          </Text>
        </Card>
      ))}
      <Space h="md" />
    </div >
  );
}

export default Portfolio;
