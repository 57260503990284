import React, { useEffect } from 'react';
import { Avatar, Text, Group, Button, Stack, Card, Center, ActionIcon, Title, Divider } from '@mantine/core';
import { IconDeviceMobile, IconMail, IconMapPin, IconDownload, IconBrandLinkedin, IconBrandGithub} from '@tabler/icons-react';

const UserProfileCard = () => {
  useEffect(() => {
    const handleDownloadClick = () => {
      gtag('event', 'download', {
        'event_category': 'CV',
        'event_label': 'Curriculum PDF'
      });
    };
    const downloadLink = document.getElementById('downloadCV');
    downloadLink.addEventListener('click', handleDownloadClick);

    return () => {
      downloadLink.removeEventListener('click', handleDownloadClick);
    };
  }, []);

  return (
    <Card withBorder shadow="sm" padding="lg" radius="lg" style={{ position: "sticky", top: "20px", zIndex: 1 }}>
      <Center>
        <Avatar
          src="/profile.png"
          size={150}
          radius={30}
          style={{ border: '4px solid white', marginBottom: '-50px', zIndex: 1 }}
        />
      </Center>
      <Card radius="lg" align="center" mt="md" shadow="none" style={{ paddingTop: '50px', backgroundColor: '#f3f6f6', padding: '20px' }}>
        <Stack>
          <Title order={3} mt="md">Roberto Verdugo</Title>
          <Text color="dimmed" size="md">Software Developer</Text>
          <Group spacing="xs" justify="center">
            <a href="https://github.com/RobertoVerdugo" target="_blank" rel="noopener noreferrer">
              <ActionIcon size="xl" radius="md" variant="subtle" color="black">
                <IconBrandGithub size={30} />
              </ActionIcon>
            </a>
            <a href="https://www.linkedin.com/in/robertoverdugodeveloper/" target="_blank" rel="noopener noreferrer">
              <ActionIcon size="xl" radius="md" variant="subtle">
                <IconBrandLinkedin size={30} />
              </ActionIcon>
            </a>
          </Group>
        </Stack>
      </Card>
      <Card radius="lg" align="center" mt="md" shadow="none" style={{ backgroundColor: '#f3f6f6',padding: '20px', marginTop: '20px' }}>
        <Stack>
          <Group>
            <ActionIcon size="xl" radius="md" variant="white" color="red">
              <IconDeviceMobile size={25} />
            </ActionIcon>
            <Text size="md">+56 9 75828626</Text>
          </Group>
          <Divider my="xd" />
          <Group>
            <ActionIcon size="xl" radius="md" variant="white" color="lime">
              <IconMail size={25} />
            </ActionIcon>
            <Text size="md">r.verdugoc@gmail.com</Text>
          </Group>
          <Divider my="xd" />
          <Group>
            <ActionIcon size="xl" radius="md" variant="white" color="pink">
              <IconMapPin size={25} />
            </ActionIcon>
            <Text size="md">La Serena, Chile</Text>
          </Group>
        </Stack>
      </Card>
      <Center mt="md">
        <Button id="downloadCV" size="lg" variant="filled" radius="md" color="blue" leftSection={<IconDownload size={20} />}
          href="/CV_RobertoVerdugo_Desarrollador.pdf"
          target="_blank"
          component="a"
          download>
          Descargar CV
        </Button>
      </Center>
    </Card>
  );
};

export default UserProfileCard;
