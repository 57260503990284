import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './CarruselComponent.css'; // Importa la hoja de estilos CSS

const CarruselComponent = ({ images }) => {
  const defaultImage = 'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png'; // Imagen por defecto si no hay imágenes
  const baseUrl = '/';
  //const baseUrl = 'https://localhost:7156/';

  return (
    <Carousel showArrows={true} showStatus={false} showThumbs={false} emulateTouch={true} infiniteLoop={true}>
      {images && images.length > 0 ? (
        images.map((image, index) => (
          <div key={index} className="carousel-container">
            <img src={`${baseUrl}${image}`} alt={`Project image ${index}`} className="carousel-image" />
          </div>
        ))
      ) : (
        <div className="carousel-container">
          <img src={defaultImage} alt="Default image" className="carousel-image" />
        </div>
      )}
    </Carousel>
  );
};

export default CarruselComponent;
