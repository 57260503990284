import React, { useContext } from 'react';
import AppRoutes from '../../AppRoutes';
import { AuthContext } from '../../AuthProvider';

export const FilterRoutes = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const limitedRoutes = ["/salir", '/projects'];
  const bannedRoutes = ["/login", '/add-project', '/edit-project/:id'];

  return AppRoutes.filter(route => {
    if ((limitedRoutes.includes(route.path) && !isAuthenticated) || (bannedRoutes.includes(route.path))) {
      return false;
    }
    return true;
  });
};
