import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextInput, Button, Textarea, Container, Group, FileInput, TagsInput } from '@mantine/core';

const EditProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState({
    name: '',
    description: '',
    date: '',
    githubLink: '',
    tags: [],
    photos: []
  });

  useEffect(() => {
    fetchProject();
  }, []);

  const fetchProject = async () => {
    try {
      const response = await axios.get(`/api/project/${id}`);
      setProject(response.data);
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  const handleTagsChange = (tags) => {
    setProject({ ...project, tags }); // Actualizamos el estado con el nuevo array de tags
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      await axios.put(`/api/project/${id}`, project, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      navigate('/projects');
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };

  return (
    <div style={{ padding: '40px' }}>
      <form onSubmit={handleSubmit}>
        <TextInput
          label="Name"
          value={project.name}
          onChange={(e) => setProject({ ...project, name: e.target.value })}
        />
        <Textarea
          label="Description"
          value={project.description}
          onChange={(e) => setProject({ ...project, description: e.target.value })}
          autosize
          minRows={5}
          maxRows={10}
        />
        <TextInput
          label="Github Link"
          value={project.githubLink}
          onChange={(e) => setProject({ ...project, githubLink: e.target.value })}
        />
        <TagsInput
          label="Tags"
          value={project.tags}
          onChange={handleTagsChange}
        />
        <Group position="right" mt="md">
          <Button type="submit">Update</Button>
          <Button color="red" onClick={() => navigate('/projects')}>Cancel</Button>
        </Group>
      </form>
    </div>
  );
};

export default EditProject;
