import React, { Component, useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { AuthProvider } from './AuthProvider';
import { MediaProvider } from './MediaProvider';
import { PageTitleProvider } from './PageTitleContext';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import Layout from './components/Layout';
import AppRoutes from './AppRoutes';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <MantineProvider>
        <AuthProvider>
          <MediaProvider>
            <PageTitleProvider routes={AppRoutes}>
              <Layout>
                <Routes>
                  {AppRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                  ))}
                </Routes>
              </Layout>
             </PageTitleProvider>
          </MediaProvider>
        </AuthProvider>
      </MantineProvider>
    );
  }
}
