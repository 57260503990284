import React from 'react';
import { Title, Text, Grid, Card, Group, Stack } from '@mantine/core';
import { IconPalette, IconCode, IconCamera, IconAppWindow } from '@tabler/icons-react';
import { useMedia } from '../MediaProvider';
const AboutSection = () => {
  const { isMobile } = useMedia();
  var divider = isMobile ? 12 : 6;
  return (
    <div style={{padding: '40px' }}>
      <Title order={1} mb="md" style={{ fontFamily: 'Roboto Slab, serif'}}>Sobre mí</Title>
      <Text size="lg" mb="md">
        Desarrollador de software titulado de Ingeniería en Computación con experiencia en proyectos freelance, especializado en el
        diseño y desarrollo de herramientas innovadoras. Mi enfoque se centra en la creación de soluciones efectivas que aborden
        necesidades específicas del usuario.
      </Text>
      <Text size="lg" mb="xl">
        Soy apasionado por la resolución de problemas y las finanzas personales.<br/>
        Estoy comprometido con ofrecer resultados de calidad en cada proyecto en el que participo.
      </Text>

      <Title order={2} mb="md">Lo que hago</Title>
      <Grid gutter="xl">
        <Grid.Col span={divider}>
          <Card shadow="sm" padding="lg" radius="lg" style={{ backgroundColor: '#fff0f0' }}>
            <Grid>
              <Grid.Col span="content">
                <IconCode size={36} color="#ff6b6b" />
              </Grid.Col>
              <Grid.Col span="auto">
                <Stack>
                  <Text weight={500} size="xl">Web Development</Text>
                  <Text size="md" c="dimmed">
                    Transformo ideas en realidades digitales con aplicaciones web robustas y eficientes utilizando ASP.NET y React.
                  </Text>
                </Stack>
              </Grid.Col>
            </Grid> 
          </Card>
        </Grid.Col>
        <Grid.Col span={divider}>
          <Card shadow="sm" padding="lg" radius="lg" style={{ backgroundColor: '#f0faff' }}>
            <Grid >
              <Grid.Col span="content">
                <IconAppWindow size={36} color="#1e90ff" />
              </Grid.Col>
              <Grid.Col span="auto">
                <Stack>
                  <Text weight={500} size="xl">App Development</Text>
                  <Text size="md" c="dimmed">
                    Creo aplicaciones de escritorio potentes y personalizadas, especializándome en .NET Framework y C#, para soluciones empresariales y personales.
                  </Text>
                </Stack>
              </Grid.Col>
            </Grid>   
          </Card>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default AboutSection;
            