import React from 'react';
import { Container, Grid, Card, Group, Text, Title, Stack, Badge, Divider } from '@mantine/core';
import { IconSchool, IconBriefcase, IconBook, IconPrompt } from '@tabler/icons-react';
import { useMedia } from '../MediaProvider';

const ResumePage = () => {

  const { isMobile } = useMedia();
  var divider = isMobile ? 12 : 6;
  const education = [
    { year: '2023', degree: 'Certificado de análisis de datos de Google', school: 'Coursera', location: 'Becado por Talento digital para Chile' },
    { year: '2019-2023', degree: 'Ingeniero en Computación', school: 'Universidad de La Serena', location: 'Becado de Excelencia Académica' },
  ];

  const experience = [
    { year: '2023 - 2024', title: 'Desarrollador de Software', company: 'Freelancer' },
  ];

  const skills = ['.NET Framework', 'API Rest', 'C#', 'Python', 'Django', 'SQL', 'NoSQL', 'React', 'HTML', 'CSS', 'Git'];

  return (
    <Container size="md" style={{ padding: '40px' }}>
      <Title order={1} mb="md" style={{ fontFamily: 'Roboto Slab, serif' }}>Currículum</Title>
      <Divider size="sm" mb="xl" />

      <Grid gutter="xl">
        <Grid.Col span={divider}>
          <Group mb="md">
            <IconSchool size={36} color="#1e90ff" />
            <Text weight={600} size="xl">Educación</Text>
          </Group>
          {education.map((edu, index) => (
            <Card key={index} shadow="sm" padding="lg" radius="lg" style={{ backgroundColor: '#fff0f0', marginBottom: '20px' }}>
              <Grid>
                <Grid.Col span="content">
                  <IconBook size={22} color="#ff6b6b" />
                </Grid.Col>
                <Grid.Col span="auto">
                  <Stack spacing={0}>
                    <Text weight={500} size="sm" c = "dimmed">{edu.year}</Text>
                    <Text size="md">{edu.degree} </Text>
                    <Text size = "sm">{edu.school}</Text>
                    <Text size="sm" color="dimmed">{edu.location}</Text>
                  </Stack>
                </Grid.Col>
              </Grid>
            </Card>
          ))}
        </Grid.Col>

        <Grid.Col span={divider}>
          <Group mb="md">
            <IconBriefcase size={36} color="#1e90ff" />
            <Text weight={600} size="xl">Experiencia</Text>
          </Group>
          {experience.map((exp, index) => (
            <Card key={index} shadow="sm" padding="lg" radius="lg" style={{ backgroundColor: '#f0faff', marginBottom: '20px' }}>
              <Grid>
                <Grid.Col span="content">
                  <IconPrompt size={22} color="#1e90ff" />
                </Grid.Col>
                <Grid.Col span="auto">
                  <Stack spacing={0}>
                    <Text weight={500} size="sm" c="dimmed">{exp.year}</Text>
                    <Text size="md">{exp.title}</Text>
                    <Text size="sm" color="dimmed">{exp.company}</Text>
                  </Stack>
                </Grid.Col>
              </Grid>
            </Card>
          ))}
        </Grid.Col>
      </Grid>

      <Title order={2} mt="xl" mb="md">Habilidades</Title>
      <Group spacing="xs">
        {skills.map((skill, index) => (
          <Badge key={index} autoContrast size="xl" color="indigo.1" radius="lg" fw={400} fs={15} tt="none">{skill}</Badge>
        ))}
      </Group>
    </Container>
  );
};

export default ResumePage;
