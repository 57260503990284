import React, { createContext, useContext } from 'react';
import { useMediaQuery } from '@mantine/hooks';

const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
  const isMobile = useMediaQuery('(max-width: 1250px)');

  return (
    <MediaContext.Provider value={{ isMobile }}>
      {children}
    </MediaContext.Provider>
  );
};

export const useMedia = () => useContext(MediaContext);
