import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTitleContext = createContext();

export const usePageTitle = () => {
  return useContext(PageTitleContext);
};

export const PageTitleProvider = ({ children, routes }) => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('Verdugo Developer');

  useEffect(() => {
    const route = routes.find((route) => route.path === location.pathname);
    const title = route ? `Verdugo Developer - ${route.name}` : 'Verdugo Developer';
    setPageTitle(title);
  }, [location, routes]);

  return (
    <PageTitleContext.Provider value={pageTitle}>
      {children}
    </PageTitleContext.Provider>
  );
};
