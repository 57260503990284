import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { TextInput, PasswordInput, Button, Stack, Fieldset } from '@mantine/core';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post('/api/account/login', { email, password });
      login(response.data.token);
      navigate('/');
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  return (
    <div style={{ padding: '40px' }}>
      <Stack>
        <TextInput
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <PasswordInput
          label="Password"
          placeholder="********"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={handleLogin}>Login</Button>
      </Stack>
    </div>
  );
};

export default Login;
