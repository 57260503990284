import React, { useState } from 'react';
import { Drawer, Burger } from '@mantine/core';
import { FilterRoutes } from './FilterRoutes';
import { RenderNavLinks } from './RenderNavLinks';
import { useMedia } from '../../MediaProvider';

export const NavMenu = () => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const { isMobile } = useMedia();
  const filteredRoutes = FilterRoutes();

  const toggleDrawer = () => {
    setDrawerOpened(!drawerOpened);
  };
  const handleLinkClick = () => {
    setDrawerOpened(false);
  };

  return (
    <>
      {isMobile ? (
        <>
          <Burger opened={drawerOpened} onClick={toggleDrawer} size="sm" />
          <Drawer
            opened={drawerOpened}
            onClose={toggleDrawer}
            title="Menu"
            padding="md"
            size="md"
          >
            <RenderNavLinks routes={filteredRoutes} onLinkClick={handleLinkClick} />
          </Drawer>
        </>
      ) : (
        <>
          <RenderNavLinks routes={filteredRoutes} />
        </>
      )}
    </>
  );
};
