import React, { useState } from 'react';
import axios from 'axios';
import { TextInput, Textarea, Button, FileInput, Group, TagsInput } from '@mantine/core';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';

const AddProject = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [githubLink, setGithubLink] = useState('');
  const [tags, setTags] = useState([]);
  const [photos, setPhotos] = useState([]);
  const navigate = useNavigate();

  const handleFileChange = (files) => {
    try {
      if (files) {
        console.log("Files selected: ", files);
        setPhotos(Array.from(files));
      }
    } catch (error) {
      console.error("Error handling file change: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('date', date);
    formData.append('githubLink', githubLink);
    tags.forEach(tag => formData.append('tags', tag));
    photos.forEach(photo => formData.append('photos', photo));
    const token = localStorage.getItem('token');

    try {
      await axios.post('/api/project', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      });
      navigate('/projects');
    } catch (error) {
      console.error('Error adding project:', error);
    }
  };

  return (
    <div style={{ padding: '40px' }}>
      <form onSubmit={handleSubmit}>
        <TextInput
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label>Description</label>
        <ReactQuill value={description} onChange={setDescription} />
        <TextInput
          label="Date"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <TextInput
          label="Github Link"
          value={githubLink}
          onChange={(e) => setGithubLink(e.target.value)}
        />
        <TagsInput
          label="Tags"
          value={tags}
          onChange={(value) => setTags(value)}
        />
        <FileInput
          label="Photos"
          multiple
          onChange={handleFileChange}
          style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        />
        <Group position="right" mt="md">
          <Button type="submit">Add Project</Button>
        </Group>
      </form>
    </div>
  );
};

export default AddProject;
