import React, { useContext } from 'react';
import { AuthContext } from '../../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Container, Text, Button, Grid } from '@mantine/core';

const Logout = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Container size="xl" style={{ marginTop: '2rem' }}>
      <Grid>
        <Grid.Col span={12}>
          <Text align="center" size="xl">
            ¿Estás seguro de que deseas cerrar sesión?
          </Text>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            <Button onClick={handleLogout} variant="outline" radius="sm">
              Cerrar sesión
            </Button>
          </div>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default Logout;
