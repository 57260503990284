import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Group, Card, Title, Stack, Container, Divider } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get('/api/project');
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleDelete = async (projectId) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`/api/project/${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchProjects();
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };


  return (
    <div style={{ padding: '40px' }}>
      <Container>
          <Stack spacing="md">
            <Title order={1} style={{ fontFamily: 'Roboto Slab, serif' }}>Dashboard</Title>
            <Divider size="sm" mb="md" />
            <Table>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {projects.map((project) => (
                  <tr key={project.id}>
                    <td>{project.name}</td>
                    <td>
                      <Group spacing="xs">
                        <Button variant="outline" onClick={() => navigate(`/edit-project/${project.id}`)}>
                          Edit
                        </Button>
                        <Button variant="outline" color="red" onClick={() => handleDelete(project.id)}>
                          Delete
                        </Button>
                      </Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Group position="right">
              <Button onClick={() => navigate('/add-project')}>Nuevo</Button>
            </Group>
          </Stack>
      </Container>
    </div>
  );
};

export default ProjectList;
