import React from 'react';
import { NavLink } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';

export const RenderNavLinks = ({ routes, onLinkClick }) => {
  const location = useLocation();

  return (
    <>
      {routes.map((route, index) => (
        <NavLink
          key={index}
          component={Link}
          to={route.path}
          label={<span style={{ fontSize: '16px' }}>{route.name}</span>}
          leftSection={route.leftSection}
          active={location.pathname === route.path}
          onClick={onLinkClick}
          variant="subtle"
          color="indigo"
        />
      ))}
    </>
  );
};
